import Login from 'containers/loginContainer'
import Dashboard from 'containers/dashboardContainer'
import Report from 'containers/reportContainer'

export const routes = [
	{
		path: '/',
		element: <Login />,
		auth: true,
	},
	{
		path: 'login',
		element: <Login />,
		auth: true,
	},
	{
		path: 'dashboard',
		element: <Dashboard />,
		protected: true,
	},
	{
		path: 'carriers/report',
		element: <Report />,
		protected: true,
	},
]
