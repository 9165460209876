import { memo, useEffect, useState, useCallback } from 'react'
import Layout from 'layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import ReportSkeleton from './components/skeleton/report_skeleton'
import CarriersTableSkeleton from './components/skeleton/carriers_table_skeleton'
import { AggregateColumnDirective, Aggregate, ColumnDirective, ColumnsDirective, GridComponent, Inject, Freeze, Sort } from '@syncfusion/ej2-react-grids'
import { AggregateColumnsDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids'
import 'bootstrap-daterangepicker/daterangepicker.css'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment-timezone'
moment.tz.setDefault("America/Chicago")

const Report = (props) => {

	const { carriers_data, total_account_data, getCarriersReportData } = props

	let gridInstance;
	const [isPageLoading, setIsPageLoading] = useState(true)
	const [isDataLoading, setIsDataLoading] = useState(true)
	const [startDate, setStartDate] = useState(moment().subtract('7', 'days').format('MM/DD/YYYY'))
	const [endDate, setEndDate] = useState(moment().subtract('1', 'day').format('MM/DD/YYYY'))
	const [filter_by, setFilterBy] = useState('daily_summary')
	const [carrier, setCarrier] = useState('AT&T')
	// const [page, setPage] = useState(1)
	const [gridKey, setGridKey] = useState(Date.now())
	const carriersList = ['AT&T', 'Boost', 'Cricket', 'H2O', 'Lyca', 'MetroPCS', 'Net10', 'Page Plus', 'Red Pocket Mobile', 'Simple Mobile', 'Telcel', 'Total Wireless', 'Tracfone', 'Ultra Mobile', 'Verizon', 'International', 'Gift Cards']

	const fetchCarriersData = useCallback(() => {
		setIsDataLoading(true)

		const from_date = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
		const to_date = moment(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD')

		getCarriersReportData({ from_date, to_date, filter_by, carrier }).then(response => {
			setIsPageLoading(false)
			setIsDataLoading(false)
			if(response.data.success) {
				setGridKey(Date.now())
			}

		}).catch(err => setIsPageLoading(false))

	}, [getCarriersReportData, startDate, endDate, filter_by, carrier])

	useEffect(() => {
		if(gridInstance) {
			gridInstance.dataSource = carriers_data
		}
	}, [gridInstance, carriers_data, gridKey])

	useEffect(() => {
		fetchCarriersData()
	}, [fetchCarriersData])

	const handleDateChange = (event, picker) => {
		setStartDate(picker.startDate.format('MM/DD/YYYY'))
		setEndDate(picker.endDate.format('MM/DD/YYYY'))
		// setPage(1)
	}

	const handleFilterByChange = (ev) => {
		setFilterBy(ev.target.value)
	}

	const isFilterByChecked = (val) =>  {
		return (filter_by === val)
	}

	const handleCarrierChange = ev => setCarrier(ev.target.value)
	const showAmount = item => Number(item.total_amount).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showSpend = item => Number(item.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCpa = item => Number(item.cpa).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
	const showCvr = item =>  Number(item.cvr/100).toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 2})
	const footerSum = props => <span>{props.Sum}</span>
	const footerCurrency = props => <span>{ Number(props.Sum).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const footerCpa = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const footerCvr = props => <span>{ Number(props.Custom).toLocaleString('en-US', {style: 'percent', 'minimumFractionDigits': 2}) }</span>
	const totalCpaFn = (args) => {
		let total_spend = (args.result.map(item => item.spend)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)
		
		if(total_sales > 0) {
			return (total_spend/total_sales)
		}

		return 0
	}

	const totalCvrFn = (args) => {
		let total_clicks = (args.result.map(item => item.clicks)).reduce((partialSum, a) => partialSum + a, 0)
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_sales/total_clicks)
		}

		return 0
	}
	const accountTotalClicks = props => <span>{ total_account_data.clicks }</span>
	const accountTotalSpend = props => <span>{ Number(total_account_data.spend).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) }</span>
	const accountTotalCpaFn = (args) => {
		let total_spend = total_account_data.spend
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)
		
		if(total_sales > 0) {
			return (total_spend/total_sales)
		}

		return 0
	}

	const accountTotalCvrFn = (args) => {
		let total_clicks = total_account_data.clicks
		let total_sales = (args.result.map(item => item.sales)).reduce((partialSum, a) => partialSum + a, 0)

		if(total_clicks > 0) {
			return (total_sales/total_clicks)
		}

		return 0
	}

	return (
		<>
			<Layout>
				{!isPageLoading &&
					<>
						<Row className="mb-3">
							<Col md={12}>
								<Card>
									<Card.Header>Carriers Report</Card.Header>

									<Card.Body>
										<Row className="mb-3">
											<Col md={3}>
												<Form.Group className="mb-4">
													<DateRangePicker onApply={handleDateChange} initialSettings={{ startDate: startDate, endDate: endDate, ranges: {
											            Today: [moment().toDate(), moment().toDate()],
											            Yesterday: [
											              moment().subtract(1, 'days').toDate(),
											              moment().subtract(1, 'days').toDate(),
											            ],
											            'Last 7 Days': [
											              moment().subtract(7, 'days').toDate(),
											              moment().subtract(1, 'day').toDate(),
											            ],
											            'Last 30 Days': [
											              moment().subtract(30, 'days').toDate(),
											              moment().subtract(1, 'day').toDate(),
											            ],
											            'This Month': [
											              moment().startOf('month').toDate(),
											              moment().endOf('month').toDate(),
											            ],
											            'Last Month': [
											              moment().subtract(1, 'month').startOf('month').toDate(),
											              moment().subtract(1, 'month').endOf('month').toDate(),
											            ],
										          	}}}>
														<input type="text" className="form-control" />
													</DateRangePicker>
												</Form.Group>
											</Col>
											<Col md={5}>
												<Form.Group>
													<Form.Check name="filter_by" inline label="Daily Summary" type="radio" value="daily_summary" onChange={handleFilterByChange} checked={isFilterByChecked('daily_summary')} />
													<Form.Check name="filter_by" inline label="Carrier Summary" type="radio" value="carrier_summary" onChange={handleFilterByChange} checked={isFilterByChecked('carrier_summary')} />
													<Form.Check name="filter_by" inline label="Carrier by Day" type="radio" value="carrier_by_day" onChange={handleFilterByChange} checked={isFilterByChecked('carrier_by_day')} />
												</Form.Group>
											</Col>

											{filter_by === 'carrier_by_day' &&
											<Col md={2}>
												<Form.Group>
													<Form.Select name="carrier" onChange={handleCarrierChange}>
														{ carriersList.map(item => <option value={item}>{item}</option> ) }
													</Form.Select>
												</Form.Group>
											</Col>
											}
										</Row>

										{!isDataLoading &&
											
											<Row>
												<Col md={12}>
													{(filter_by === 'daily_summary' || filter_by === 'carrier_by_day') &&
														<GridComponent ref={grid => gridInstance = grid} allowSorting={true} enableStickyHeader={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }}>
															<ColumnsDirective>
																<ColumnDirective field='date' headerText='Date' />
																<ColumnDirective field='sales' headerText='Sales' />
																<ColumnDirective field='total_amount' headerText='Refill Amount' template={showAmount} />
																<ColumnDirective field='clicks' headerText='Clicks' />
																<ColumnDirective field='spend' headerText='Cost' template={showSpend} />
																<ColumnDirective field='cpa' headerText='CPA' template={showCpa} />
																<ColumnDirective field='cvr' headerText='Conv. Rate' template={showCvr} />
															</ColumnsDirective>
															<Inject services={[Freeze, Sort, Aggregate]}/>

															<AggregatesDirective>
																<AggregateDirective>
																	<AggregateColumnsDirective>
																		<AggregateColumnDirective field='date' type='Custom' footerTemplate={() => <span>Total: Carriers</span>} />
																		<AggregateColumnDirective field='sales' type='Sum' footerTemplate={footerSum} />
																		<AggregateColumnDirective field='total_amount' type='Sum' footerTemplate={footerCurrency} />
																		<AggregateColumnDirective field='clicks' type='Sum' footerTemplate={footerSum} />
																		<AggregateColumnDirective field='spend' type='Sum' footerTemplate={footerCurrency} />
																		<AggregateColumnDirective field='cpa' type='Custom' customAggregate={totalCpaFn} footerTemplate={footerCpa} />
																		<AggregateColumnDirective field='cvr' type='Custom' customAggregate={totalCvrFn} footerTemplate={footerCvr} />
																	</AggregateColumnsDirective>
																</AggregateDirective>

																{filter_by === 'daily_summary' &&
																	<AggregateDirective>
																		<AggregateColumnsDirective>
																			<AggregateColumnDirective field='date' type='Custom' footerTemplate={() => <span>Total: Account</span>} />
																			<AggregateColumnDirective field='sales' type='Sum' footerTemplate={footerSum} />
																			<AggregateColumnDirective field='total_amount' type='Sum' footerTemplate={footerCurrency} />
																			<AggregateColumnDirective field='clicks' type='Sum' footerTemplate={accountTotalClicks} />
																			<AggregateColumnDirective field='spend' type='Sum' footerTemplate={accountTotalSpend} />
																			<AggregateColumnDirective field='cpa' type='Custom' customAggregate={accountTotalCpaFn} footerTemplate={footerCpa} />
																			<AggregateColumnDirective field='cvr' type='Custom' customAggregate={accountTotalCvrFn} footerTemplate={footerCvr} />
																		</AggregateColumnsDirective>
																	</AggregateDirective>
																}
															</AggregatesDirective>

														</GridComponent>
													}

													{filter_by === 'carrier_summary' &&
														<GridComponent ref={grid => gridInstance = grid} allowSorting={true} enableStickyHeader={true} frozenColumns={1} loadingIndicator={{ indicatorType: 'Shimmer' }}>
															<ColumnsDirective>
																<ColumnDirective field='carrier_name' headerText='Carrier' />
																<ColumnDirective field='sales' headerText='Sales' />
																<ColumnDirective field='total_amount' headerText='Refill Amount' template={showAmount} />
																<ColumnDirective field='clicks' headerText='Clicks' />
																<ColumnDirective field='spend' headerText='Cost' template={showSpend} />
																<ColumnDirective field='cpa' headerText='CPA' template={showCpa} />
																<ColumnDirective field='cvr' headerText='Conv. Rate' template={showCvr} />
															</ColumnsDirective>
															<Inject services={[Freeze, Sort, Aggregate]}/>

															<AggregatesDirective>
																<AggregateDirective>
																	<AggregateColumnsDirective>
																		<AggregateColumnDirective field='carrier_name' type='Custom' footerTemplate={() => <span>Total: Carriers</span>} />
																		<AggregateColumnDirective field='sales' type='Sum' footerTemplate={footerSum} />
																		<AggregateColumnDirective field='total_amount' type='Sum' footerTemplate={footerCurrency} />
																		<AggregateColumnDirective field='clicks' type='Sum' footerTemplate={footerSum} />
																		<AggregateColumnDirective field='spend' type='Sum' footerTemplate={footerCurrency} />
																		<AggregateColumnDirective field='cpa' type='Custom' customAggregate={totalCpaFn} footerTemplate={footerCpa} />
																		<AggregateColumnDirective field='cvr' type='Custom' customAggregate={totalCvrFn} footerTemplate={footerCvr} />
																	</AggregateColumnsDirective>
																</AggregateDirective>

																<AggregateDirective>
																	<AggregateColumnsDirective>
																		<AggregateColumnDirective field='date' type='Custom' footerTemplate={() => <span>Total: Account</span>} />
																		<AggregateColumnDirective field='sales' type='Sum' footerTemplate={footerSum} />
																		<AggregateColumnDirective field='total_amount' type='Sum' footerTemplate={footerCurrency} />
																		<AggregateColumnDirective field='clicks' type='Sum' footerTemplate={accountTotalClicks} />
																		<AggregateColumnDirective field='spend' type='Sum' footerTemplate={accountTotalSpend} />
																		<AggregateColumnDirective field='cpa' type='Custom' customAggregate={accountTotalCpaFn} footerTemplate={footerCpa} />
																		<AggregateColumnDirective field='cvr' type='Custom' customAggregate={accountTotalCvrFn} footerTemplate={footerCvr} />
																	</AggregateColumnsDirective>
																</AggregateDirective>
															</AggregatesDirective>								
														</GridComponent>
													}
												</Col>
											</Row>
										}
										{isDataLoading &&
											<CarriersTableSkeleton />
										}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</>
			}

			{isPageLoading &&
				<ReportSkeleton />
			}
			</Layout>
		</>
	)
}

export default memo(Report)