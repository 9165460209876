import { connect } from 'react-redux'
import Report from 'pages/carriers/report'
import { getCarriersReportData } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.reportReducer
   }
}

const mapActionsToProps = {
   getCarriersReportData,
}

export default connect(mapStateToProps, mapActionsToProps)(Report)